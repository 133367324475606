import { styled } from "styled-components";
import PaymentModal from "./Payment/PaymentModal";
import { useCallback, useContext, useEffect, useState } from "react";
import { PaymentContext } from "../contexts/PaymentContext";
import { API_BASE, TOASTPROPS } from "../shared/Constants";
import { useAuth0 } from "@auth0/auth0-react";
import { ToastContainer, toast } from "react-toastify";

export interface UserConfigs {
    user_has_registered_cards: boolean;
    amount_to_recharge: number;
    min_threshold_to_recharge: number;
}

export default function Recharge() {
    const { 
        paymentStage,
        setPaymentStage, 
        setPaymentOption,
    } = useContext(PaymentContext);

    const handleAutoButton = () => {
        setPaymentOption("automatic");
        setPaymentStage("methods");
    }

    const handleCardsButton = () => {
        setPaymentOption("cards-only"); // Used only to add new cards or remove existing ones
        setPaymentStage("methods");
    }

    const handleSingleButton = () => {
        setPaymentOption("single");
        setPaymentStage("methods");
    }

    const { getAccessTokenSilently } = useAuth0();
    const [userConfigs, setUserConfigs] = useState<UserConfigs>({ user_has_registered_cards: false, amount_to_recharge: 0, min_threshold_to_recharge: 0 });

    const [couponCode, setCouponCode] = useState("");
    const [isApplyingCoupon, setIsApplyingCoupon] = useState(false);

    const parseComma = (value: number) => {
        return value.toString().replace(".", ",");
    }

    const getUserConfigs = useCallback(async () => {
        try {
            const url = `${API_BASE}/api/billing/user-configs`;
            const access_token = await getAccessTokenSilently();
            const response = await fetch(url, {
                method: "GET",
                headers: {
                    "Authorization": `Auth ${access_token}`,
                    "Content-Type": "application/json",
                }
            });

            if (response.ok) {
                const data = await response.json();
                setUserConfigs(data);
            } else {
                toast.error("Erro ao recuperar configurações de recarga.", TOASTPROPS);
            }
        } catch (error) {
            toast.error("Erro ao recuperar configurações de recarga.", TOASTPROPS);
        }
    }, []);

    useEffect(() => {
        getUserConfigs();
    }, []);

    useEffect(() => {
        if (paymentStage === "refresh"){
            getUserConfigs();
            setPaymentStage("");
        }
    }, [paymentStage]);

    const handleDisableAuto = async () => {
        try {
            const url = `${API_BASE}/api/billing/config-auto-charge?amount_to_load=0&threshold=0&payment_id=`;
            const access_token = await getAccessTokenSilently();
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Authorization": `Auth ${access_token}`,
                    "Content-Type": "application/json",
                }
            });
            if (response.ok) {
                toast.success("Recarga automática desativada com sucesso", TOASTPROPS);
                setPaymentStage("refresh");
            } else {
                toast.error("Erro ao desativar recarga automática", TOASTPROPS);
            }
        } catch (error) {
            toast.error("Erro ao desativar recarga automática", TOASTPROPS);
        }
    }

    const handleApplyCoupon = async () => {
        if (!couponCode) {
            return;
        }

        setIsApplyingCoupon(true);

        try {
            const url = `${API_BASE}/api/billing/coupon?coupon=${couponCode}`;
            const access_token = await getAccessTokenSilently();
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Authorization": `Auth ${access_token}`,
                    "Content-Type": "application/json",
                }
            });

            if (response.ok) {
                const data = await response.json();
                if (data.status === "invalid") {
                    toast.error("Cupom inválido. Verifique o código.", TOASTPROPS);
                } else if (data.status === "success") {
                    toast.success(`Cupom aplicado com sucesso! Você ganhou R$ ${data.amount} de crédito.`, TOASTPROPS);
                    setPaymentStage("refresh");
                } else if (data.status === "coupon_already_used") {
                    toast.error("Cupom já foi utilizado.", TOASTPROPS);
                } else if (data.status === "expired") {
                    toast.error("Cupom expirado.", TOASTPROPS);
                }
            } else {
                toast.error("Erro ao aplicar o cupom. Verifique o código.", TOASTPROPS);
            }
        } catch (error) {
            toast.error("Erro ao aplicar o cupom.", TOASTPROPS);
        } finally {
            setIsApplyingCoupon(false);
        }
    }

    return (
        <Container>
            <Desc style={{marginLeft: "5px"}}>Ganhe R$ 20,00 de crédito na primeira recarga (via cartão ou boleto bancário) ou ao cadastrar um cartão.</Desc>
            <Desc style={{marginLeft: "5px"}}>Os créditos nunca expiram.</Desc>
            <ItemContainer>
                <TitleContainer>
                    <Title>Recarga Única</Title>
                </TitleContainer>
                <Desc>Adicione créditos com uma recarga única utilizando cartão ou boleto bancário.</Desc>
                <ClickableText onClick={handleSingleButton}>Adicionar Créditos</ClickableText>
            </ItemContainer>
            <ItemContainer>
                <TitleContainer>
                    <Title>Recarga Automática</Title>
                    <SubTitle active={userConfigs.amount_to_recharge > 0}>{userConfigs.amount_to_recharge > 0 ? "Ativo" : "Inativo"}</SubTitle>
                </TitleContainer>
                <Desc>Defina um valor a ser recarregado quando o saldo de sua conta atingir o valor mínimo.</Desc>
                <Desc>Valor atual de recarga: <strong>R$ {parseComma(userConfigs.amount_to_recharge)}</strong></Desc>
                <Desc>Quando saldo for inferior a: <strong>R$ {parseComma(userConfigs.min_threshold_to_recharge)}</strong></Desc>
                <ButtonsContainer>
                    <ClickableText onClick={handleAutoButton}>Configurar</ClickableText>
                    {userConfigs.amount_to_recharge > 0 && <DisableText onClick={handleDisableAuto}>Desativar</DisableText>}
                </ButtonsContainer>
            </ItemContainer>
            <ItemContainer>
                <TitleContainer>
                    <Title>Cartões Cadastrados</Title>
                </TitleContainer>
                <Desc>Adicione ou remova formas de pagamento.</Desc>
                <ClickableText onClick={handleCardsButton}>Gerenciar</ClickableText>
            </ItemContainer>
            <ItemContainer>
                <TitleContainer>
                    <Title>Cupom</Title>
                </TitleContainer>
                <Desc>Adicione créditos a partir de um código promocional.</Desc>
                <CouponInputContainer>
                    <CouponInput 
                        type="text" 
                        placeholder="Digite o código do cupom"
                        value={couponCode}
                        onChange={(e) => setCouponCode(e.target.value)}
                    />
                    <ApplyCouponButton onClick={handleApplyCoupon} disabled={isApplyingCoupon}>
                        {isApplyingCoupon ? "Aplicando..." : "Aplicar"}
                    </ApplyCouponButton>
                </CouponInputContainer>
            </ItemContainer>
            <PaymentModal />
            <ToastContainer />
        </Container>
    )
}

const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 5px;
    justify-content: space-between;
    width: 180px;
`;

const CouponInputContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 5px;
`;

const CouponInput = styled.input`
    padding: 10px;
    font-size: 1rem;
    flex: 1;
    margin-right: 10px;
    border: 2px solid ${(props) => props.theme.input_border};
    border-radius: 5px;
    font-family: Plus Jakarta Sans, sans-serif;
    background-color: white;

    &:focus {
        outline: none;
        border: 2px solid ${(props) => props.theme.light_blue};
    }
`;

const ApplyCouponButton = styled.button`
    padding: 10px 20px;
    font-size: 1rem;
    font-weight: bold;
    color: white;
    background-color: ${(props) => props.theme.light_blue};
    border: none;
    border-radius: 5px;
    cursor: pointer;

    &:disabled {
        background-color: ${(props) => props.theme.disabled_blue};
        cursor: not-allowed;
    }

    &:hover:not(:disabled) {
        background-color: ${(props) => props.theme.light_blue_hover};
    }
    
    &:active:not(:disabled) {
        background-color: ${(props) => props.theme.light_blue};
    }
`;

const ClickableText = styled.span`
    color: ${(props) => props.theme.light_blue};
    text-decoration: underline;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 700;
    font-family: Plus Jakarta Sans, sans-serif;
    display: inline-block;

    &:hover {
        color: ${(props) => props.theme.light_blue_hover};
    }

    &:active {
        color: ${(props) => props.theme.light_blue};
    }
`;

const DisableText = styled.span`
    color: ${(props) => props.theme.red};
    text-decoration: underline;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 700;
    font-family: Plus Jakarta Sans, sans-serif;
    display: inline-block;
    margin: 0px;
    &:hover {
        color: ${(props) => props.theme.red_hover};
    }
    &:active {
        color: ${(props) => props.theme.red};
    }
`;

const TitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
`;

const SubTitle = styled.h2<{ active: boolean }>`
    font-size: 1.5rem;
    font-weight: 500;
    margin: 0px;
    margin-left: 10px;
    color: ${(props) => (props.active ? props.theme.light_blue : props.theme.desc_color)};
    font-family: Plus Jakarta Sans, sans-serif;
`;

const ItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    width: 600px;
    margin-right: 20px;
    border: 2px solid ${(props) => props.theme.input_border};
    border-radius: 15px;
    padding: 15px;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);

    @media (max-width: 1300px) {
        width: 90%;
    }
`;

const Desc = styled.p`
    font-size: 1.1rem;
    font-weight: 400;
    margin: 0px;
    margin-bottom: 8px;
    color: ${(props) => props.theme.dark_blue};
    font-family: Plus Jakarta Sans, sans-serif;
`;

const Title = styled.h1`
    font-size: 1.5rem;
    font-weight: 600;
    margin: 0px;
    color: ${(props) => props.theme.dark_blue};
    font-family: Plus Jakarta Sans, sans-serif;
`;

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    @media (max-width: 768px) {
        padding-left: 5px;
    }
`;
